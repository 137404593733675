.calculate-block {
    color: #00CED1;
    display: flex;
    flex-direction: column;
    margin-left: 7%;
    font-size: 16px;
}

.number-input {
    margin: 8px 10px;
    padding: 4px;
    background-color: #53565B;
    color: #00CED1;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    width: 55%;
    min-height: 40px;
    overflow-y: hidden;
    resize: none;
}

.operation-choice {
    display: flex;
    align-items: center;
    margin: 10px 15%;
}

.operation-choice span {
    margin-right: 35px;
}

.operation-selector {
    padding: 4px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    background-color: #53565B;
    color: #00CED1;
    cursor: pointer;
}

.calculate-button {
    display: block;
    margin: 20px 10px;
    padding: 10px;
    width: 55%;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    background-color: #53565B;
    color: #00CED1;
    cursor: pointer;
}

.calculate-button:hover {
    background-color: #b4eded;
}

.calculate-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}